var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":!_vm.wideLayout,"justify-center":!_vm.wideLayout,"fill-height":!_vm.wideLayout}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":_vm.wideLayout,"lg12":_vm.wideLayout,"md10":!_vm.wideLayout,"lg10":!_vm.wideLayout,"xl8":!_vm.wideLayout}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageOptions: [5,10,15,25,50, 100]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"white"}},[_c('v-toolbar-title',{staticClass:"font-weight-regular body-1"},[_vm._v(_vm._s(_vm.settings.title))]),_c('v-spacer'),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.breakPoint),expression:"breakPoint"}],attrs:{"xs4":"","md4":"","lg4":""}},[_c('v-text-field',{staticClass:"pt-4",attrs:{"label":"Search...","append-icon":"mdi-magnify","single-line":"","clearable":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''},function($event){return _vm.doSearch()}],"click:clear":function($event){return _vm.doSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.btnRefresh)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar_btn_fab",attrs:{"elevation":"0","color":"secondary","fab":"","x-small":"","dark":""},on:{"click":_vm.refreshDataTable}},on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,false,948541482)},[_c('span',[_vm._v("Refresh data")])]):_vm._e(),(_vm.btnToggleColumns)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar_btn_fab",attrs:{"elevation":"0","color":"secondary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.toggleColumns()}}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,349297520)},[_c('span',[_vm._v("Show / hide columns")])]):_vm._e()],1),_c('br')]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.telemetry !== null && item.telemetry.last_maintenance !== null && item.telemetry.last_maintenance.tag !== '' && item.telemetry.last_maintenance.tag !== null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"id":"dt_btn_edit","color":"#5D4037","small":""},on:{"click":function($event){return _vm.searchItem(item)}}},on),[_vm._v(" mdi-file-search ")])]}}],null,true)},[_c('span',[_vm._v("Find maintenance")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"id":"dt_btn_edit","color":"purple","small":"","disabled":item.last_invoice_id == null || _vm.getUserRole() === 'user'},on:{"click":function($event){return _vm.addItem(item)}}},on),[_vm._v(" mdi-plus-circle ")])]}}],null,true)},[_c('span',[_vm._v("Add maintenance")])])]}},{key:"item.business_premise_name",fn:function(ref){
            var item = ref.item;
return [(item.business_premise_name !== null && item.business_premise_name !=='null' && item.business_premise_name !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getBusinessPremiseNameColor(item),"dark":"","small":""}},[_vm._v(_vm._s(item.business_premise_name))]):_c('span',[_vm._v(_vm._s(item.business_premise_name))])]}},{key:"item.cashier_nr",fn:function(ref){
            var item = ref.item;
return [(item.cashier_nr !== null && item.cashier_nr !=='null' && item.cashier_nr !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getCashierNameColor(item),"dark":"","small":""}},[_vm._v(_vm._s(item.cashier_nr))]):_c('span',[_vm._v(_vm._s(item.cashier_nr))])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [(item.name !== null && item.name !=='null' && item.name !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getCashierNameColor(item),"dark":"","small":""}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.all_entrances",fn:function(ref){
            var item = ref.item;
return [(item !== null && item.all_entrances !== null && item.all_entrances !=='null' && item.all_entrances !== '')?_c('v-chip',{attrs:{"label":"","color":"pink darken-1","dark":"","small":""}},[_vm._v(_vm._s(item.all_entrances))]):_c('v-chip',{attrs:{"label":"","color":"pink darken-1","dark":"","small":""}},[_vm._v("0")])]}},{key:"item.entrances_since_last_maintenance",fn:function(ref){
            var item = ref.item;
return [(item.telemetry !== null && item.entrances_since_last_maintenance !== null && item.entrances_since_last_maintenance !=='null' && item.entrances_since_last_maintenance !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getEntrancesColor(item),"dark":"","small":""}},[_vm._v(_vm._s(item.entrances_since_last_maintenance))]):_c('v-chip',{attrs:{"label":"","color":"success","dark":"","small":""}},[_vm._v("0")])]}},{key:"item.telemetry.last_maintenance.maintenance_timestamp",fn:function(ref){
            var item = ref.item;
return [(item.telemetry !== null && item.telemetry.last_maintenance !== null )?_c('span',{attrs:{"label":""}},[_vm._v(_vm._s(item.telemetry.last_maintenance.maintenance_timestamp + " [ Tag:" + item.telemetry.last_maintenance.tag + " ]"))]):_c('span',[_vm._v("No maintenance yet")])]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"absolute":"","indeterminate":"","height":_vm.progressHeight()}})]},proxy:true}])},[_c('template',{slot:"no-data"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchQuery),expression:"!searchQuery"}],staticClass:"no_data"},[_vm._v(_vm._s((this.loading) ? "Data processing in progress..." : "No data to display."))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"no_data"},[_vm._v(_vm._s((this.loading) ? 'Data processing for keyword "' + this.search + '" is in progress...' : ("No results for keyword »" + (this.search) + "«.")))])]),_c('template',{slot:"loading"},[_c('div',{staticClass:"no_data"},[_vm._v("Data processing in progress ...")])])],2)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"490"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-icon',{attrs:{"text":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.dialog_text)+" ")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},nativeOn:{"click":function($event){return _vm.dialogCancel()}}},[_vm._v("Prekliči")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"color":"success"},nativeOn:{"click":function($event){return _vm.dialogConfirm()}}},[_vm._v("Nadaljuj")])],1)],1)],1)],1),_c('add-maintenance-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }